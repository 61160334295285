import * as React from "react";

export const calcDate = (startDate: Date, endDate: Date) => {
  /*
   * calcDate() : Calculates the difference between two dates
   * @date1 : "First Date in the format M-D-Y"
   * @date2 : "Second Date in the format M-D-Y"
   * return : Array
   */
  //Get the Timestamp
  let date1 = startDate.getTime();
  let date2 = endDate.getTime();

  let calc;
  //Check which timestamp is greater
  if (date1 > date2) {
    calc = new Date(date1 - date2);
  } else {
    calc = new Date(date2 - date1);
  }
  //Retrieve the date, month and year
  let calcFormatTmp = calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
  //Convert to an array and store
  let calcFormat = calcFormatTmp.split("-");
  //Subtract each member of our array from the default date
  let days_passed = Math.abs(parseInt(calcFormat[0])) - 1;
  let months_passed = Math.abs(parseInt(calcFormat[1])) - 1;
  let years_passed = Math.abs(parseInt(calcFormat[2])) - 1970;

  //Set up custom text
  const yrsTxt = ["year ago", "years ago"];
  const mnthsTxt = ["month ago", "months ago"];
  const daysTxt = ["day ago", "days ago"];

  //Convert to days and sum together
  let total_days = years_passed * 365 + months_passed * 30.417 + days_passed;

  //display result with custom text
  if (years_passed === 0) {
    if (months_passed === 0) {
      if (days_passed === 0) {
        return <span className="global--paragraph text-[24px] font-500">Today</span>;
      } else {
        return (days_passed == 1
        ? (<>{days_passed} <span className="global--label text-interface-300">{daysTxt[0]}</span></>)
        : days_passed > 1
        ? (<>{days_passed} <span className="global--label text-interface-300">{daysTxt[1]}</span></>)
        : "");
      }
    } else {
      return (months_passed == 1
        ? (<>{months_passed} <span className="global--label text-interface-300">{mnthsTxt[0]}</span></>)
        : months_passed > 1
        ? (<>{months_passed} <span className="global--label text-interface-300">{mnthsTxt[1]}</span></>)
        : "")
    }
  } else {
    return (years_passed == 1
      ? (<>{years_passed} <span className="global--label text-interface-300">{yrsTxt[0]}</span></>)
      : years_passed > 1
      ? (<>{years_passed} <span className="global--label text-interface-300">{yrsTxt[1]}</span></>)
      : "")
  }
};
