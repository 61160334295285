import * as React from "react";

export const generateTitle = ({ title, gray, green, fontClass }: {title: string, gray?: string, green?: string, fontClass?: string}) => {
  if (gray) {
    if (title.includes(gray)) {
      const titleArray = title.split(gray);
      return (
        <h2 className={fontClass ? fontClass : "global--heading"}>
          {titleArray[0]}
          <span className="text-interface-300">{gray}</span>
          {titleArray[1]}
        </h2>
      );
    }
  } else if (green) {
    if (title.includes(green)) {
      const titleArray = title.split(green);
      return (
        <h2 className={fontClass ? fontClass : "global--heading"}>
          {titleArray[0]}
          <span className="text-brand-green">{green}</span>
          {titleArray[1]}
        </h2>
      );
    }
  }

  return <h2 className={fontClass ? fontClass : "global--heading"}>{title}</h2>;
};
