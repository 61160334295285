export const USER_MEMBERSHIP_MAP = {
  'elite': 'Elite',
  'prestige': 'Prestige',
  'vip': 'VIP',
};

export interface UserLoyalty {
  name: string;
  currentProgress: number;
  highlightedColIndex: number;
}

export const getUserLoyalty = (loanCount: number) => {
  const userLoyalty = {} as UserLoyalty;

  if (loanCount >= 20) {
    userLoyalty.currentProgress = 0;
    userLoyalty.name = USER_MEMBERSHIP_MAP.vip;
    userLoyalty.highlightedColIndex = 2;
  } else if (loanCount >= 10) {
    userLoyalty.currentProgress = loanCount - 10;
    userLoyalty.name = USER_MEMBERSHIP_MAP.prestige;
    userLoyalty.highlightedColIndex = 1;
  } else {
    userLoyalty.currentProgress = Math.max(loanCount, 0);
    userLoyalty.name = USER_MEMBERSHIP_MAP.elite;
    userLoyalty.highlightedColIndex = 0;
  }

  return userLoyalty;
};
