import * as React from "react";
import ReactMarkdown from "react-markdown";
import { GatsbyLink } from "@components/gatsby-link";

export const generateLink = (content: string) => {
  // Create a text with links with the good styles and props
  return (
    <ReactMarkdown
      components={{
        a: ({ node, ...props }) => {
          if (props.href)
            return (
              <GatsbyLink
                target="_blank"
                to={props.href}
                rel={props.href.startsWith("https://icash.ca") ? "noopener" : "noopener nofollow"}
              >
                {props.children}
              </GatsbyLink>
            );
          else return null;
        }
      }}
      children={content}
    />
  );
};
